import React from "react";
import Seo from "../components/seo";
import LayoutWork from "../components/layouts/layoutWork";

const ThankYou = () => (
  <LayoutWork>
    <Seo title="Thank you" />
    <div className="w-full mx-auto text-5xl tracking-wide pt-20">
    <h1 className="w-full font-main mx-auto text-center">Thanks</h1>
    <p className="w-full font-main mx-auto text-center">Your message has been sent!</p>
    </div>
  </LayoutWork>
);

export default ThankYou;
